<template>
  <div>
    <div class="tu-container-title">
        <h2 class="page-title">全部消息</h2>
    </div>

    <div>
        <v-container>
        <v-tabs v-model="tab">
          <v-tab>
            <div>未读</div>
          </v-tab>

          <v-tab>
            <div>已读</div>
          </v-tab>

        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
              <!-- 1111ss111 -->
            <UnreadStatistics :selObj="selObj" />
          </v-tab-item>
          <v-tab-item>
              <!-- 22222sss111 -->
            <IsreadStatistics  :selObj="selObj" />
          </v-tab-item>
          

        </v-tabs-items>
				
      </v-container>
    </div>

  </div>
</template>
<script>

export default {
  name: "messagePage",
  components: {
    UnreadStatistics: () => import('@/components/message/UnreadStatistics.vue'),
    IsreadStatistics: () => import('@/components/message/IsreadStatistics.vue'),
  },
  data(){
      return{
          tab: null,
          selObj: {
            type: 1,
            tab: 1
        },

      }
  }
};
</script>
<style scoped>
.tu-container-title {
    background-color: #f4f6f9;
    padding: 20px;
}
.page-title{
    font-size: 20px!important;
    font-weight: 700!important;
    color: #141C28;
}
</style>